import { apiClient } from "@/services/HttpService";

// 获取电池列表
export function fetchBatteryList(params) {
  return apiClient.get(
    `/battery-config?pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

// 编辑电池
export function editBattery(params) {
  return apiClient.post(`/battery-config`, params);
}

// 删除电池
export function deleteBattery(productId) {
  return apiClient.delete(`/battery-config/${productId}`);
}
