<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb />
    </div>
    <section class="content-container">
      <a-row>
        <a-button type="primary" @click="onAdd">新增</a-button>
      </a-row>
      <a-table
        class="table"
        :columns="tblColumns"
        :data-source="tblData"
        :loading="loading"
        :pagination="pagination"
        rowKey="productId"
        @change="onPage"
      >
        <a-space slot="tag" slot-scope="text, record">
          <div class="tag">
            <div class="tag__item" v-if="record.shareFlag">共享业务</div>
            <div class="tag__item" v-if="record.leaseFlag">租赁业务</div>
            <div class="tag__item" v-if="record.leasePurchaseFlag">
              以租代购
            </div>
          </div>
        </a-space>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <a @click="onEdit(record)">编辑</a>
            <a @click="onDelete(record.productId)">删除</a>
          </a-space>
        </template>
      </a-table>
    </section>

    <a-modal
      v-model="visible"
      :title="modalTitle"
      @cancel="onCancel"
      @ok="onSubmit"
    >
      <a-form class="advanced-search-form" :form="form">
        <a-form-item label="电池类型">
          <a-select
            v-decorator="[
              `productId`,
              { rules: [{ required: true, message: `请选择电池类型` }] }
            ]"
            placeholder="请选择电池类型"
            :options="partsList"
            :disabled="disabled"
          />
        </a-form-item>
        <a-form-item label="业务类型">
          <a-checkbox-group
            v-decorator="[
              'flag',
              { rules: [{ required: true, message: '请选择业务类型' }] }
            ]"
          >
            <a-checkbox value="shareFlag">共享业务</a-checkbox>
            <a-checkbox value="leaseFlag">租赁业务</a-checkbox>
            <a-checkbox value="leasePurchaseFlag">以租代购</a-checkbox>
          </a-checkbox-group>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import {
  deleteBattery,
  editBattery,
  fetchBatteryList
} from "@/services/BatteryService";
import { fetchPartsList } from "@/services/ProductService";

const tblColumns = [
  {
    title: "电池类型",
    dataIndex: "productName",
    width: "40%"
  },
  {
    title: "业务类型",
    dataIndex: "tag",
    scopedSlots: { customRender: "tag" },
    width: "40%"
  },
  {
    title: "操作",
    scopedSlots: { customRender: "action" },
    width: "20%"
  }
];

export default {
  name: "BatterySpecification",
  components: {
    Breadcrumb
  },
  data() {
    return {
      tblColumns,
      tblData: [],
      loading: false,
      pagination: {
        showQuickJumper: true,
        hideOnSinglePage: true
      },
      visible: false,
      modalTitle: "",
      form: this.$form.createForm(this),
      partsList: [],
      disabled: false
    };
  },
  mounted() {
    this.loadPartsList();
    this.loadList();
  },
  methods: {
    // 加载列表
    loadList() {
      this.loading = true;
      const params = {
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10
      };
      fetchBatteryList(params)
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            this.tblData = data.records;
            const pagination = { ...this.pagination };
            pagination.current = data.pageNum;
            pagination.total = data.total;
            pagination.pageSize = data.pageSize;
            pagination.showTotal = function(total, range) {
              return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
            };
            this.pagination = pagination;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.loadList();
    },
    // 新增
    onAdd() {
      this.visible = true;
      this.disabled = false;
      this.modalTitle = "新增";
    },
    // 编辑
    onEdit(record) {
      this.modalTitle = "编辑";
      this.visible = true;
      let flag = [];
      if (record.shareFlag) {
        flag.push("shareFlag");
      }
      if (record.leaseFlag) {
        flag.push("leaseFlag");
      }
      if (record.leasePurchaseFlag) {
        flag.push("leasePurchaseFlag");
      }
      this.$nextTick(() => {
        this.form.setFieldsValue({
          productId: record.productId,
          flag: flag
        });
      });
      this.disabled = true;
    },
    // 取消
    onCancel() {
      this.disabled = false;
      this.visible = false;
      this.form.resetFields();
    },
    // 加载配件列表
    loadPartsList() {
      fetchPartsList("配件").then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.partsList = [];
          if (data && data.length > 0) {
            data.forEach(item => {
              this.partsList.push({
                label: item.productName,
                value: item.productId
              });
            });
          }
        }
      });
    },
    // 提交
    onSubmit() {
      if (this.modalTitle === "新增") {
        this.onSubmitAdd();
      } else {
        this.onSubmitEdit();
      }
    },
    // 提交新增
    onSubmitAdd() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            productId: values.productId,
            shareFlag: values.flag.indexOf("shareFlag") !== -1,
            leasePurchaseFlag: values.flag.indexOf("leasePurchaseFlag") !== -1,
            leaseFlag: values.flag.indexOf("leaseFlag") !== -1
          };
          editBattery(params).then(resp => {
            if (resp.data.code === "SUCCESS") {
              this.$message.success("新增成功");
              this.pagination.current = 1;
              this.loadList();
              this.visible = false;
              this.form.resetFields();
            }
          });
        }
      });
    },
    // 提交编辑
    onSubmitEdit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            productId: values.productId,
            shareFlag: values.flag.indexOf("shareFlag") !== -1,
            leasePurchaseFlag: values.flag.indexOf("leasePurchaseFlag") !== -1,
            leaseFlag: values.flag.indexOf("leaseFlag") !== -1
          };
          editBattery(params).then(resp => {
            this.disabled = false;
            if (resp.data.code === "SUCCESS") {
              this.$message.success("编辑成功");
              this.loadList();
              this.visible = false;
              this.form.resetFields();
            }
          });
        }
      });
    },
    // 删除
    onDelete(productId) {
      const that = this;
      this.$confirm({
        title: "确定要删除吗?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          deleteBattery(productId).then(resp => {
            if (resp.data.code === "SUCCESS") {
              that.$message.success("删除成功");
              that.pagination.current = 1;
              that.loadList();
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.content-container {
  margin: 30px;
  min-height: 1000px;
  background-color: white;
}

.table {
  margin-top: 20px;
}

.ant-form-item {
  display: flex;
}

/deep/ .ant-form-item-control-wrapper {
  flex: 1;
}

.tag {
  display: flex;
  align-items: center;
}

.tag__item {
  padding: 2px 5px;
  background: #fdf7f3;
  color: #e89353;
  margin-right: 5px;
}
</style>
